import { Link } from '@remix-run/react'
import type { Product, Vendor } from '@repo/db/schema'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'
import { VendorIcon } from './VendorIcon'

export const ProductLink = ({
  name,
  productId,
  vendorId,
  iconUrl,
  prefetch = 'intent',
  className,
  ...props
}: {
  name: Product['name']
  productId: Product['id']
  vendorId: Vendor['id']
  iconUrl: Vendor['iconUrl']
} & Omit<ComponentProps<typeof Link>, 'to'>) => {
  return (
    <Link
      className={cn(
        'flex items-center gap-1 underline-offset-2 hover:underline text-muted-foreground',
        className
      )}
      to={{
        pathname: `/apps/${vendorId}`,
        search: `product=${productId}`
      }}
      prefetch={prefetch}
      {...props}
    >
      <VendorIcon src={iconUrl} className="size-4" />
      <span>{name}</span>
    </Link>
  )
}
